import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "./App.css";

function App() {
  const [paymentType, setPaymentType] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState(
    "Select a payment plan to see the amount."
  );
  const [showPayPal, setShowPayPal] = useState(false);

  useEffect(() => {
    if (paymentType === "monthly") {
      setAmount("£49.99");
      setDescription("Monthly payment of £49.99.");
      setShowPayPal(true);
    } else if (paymentType === "yearly") {
      setAmount("£479.88");
      setDescription("One-time payment of £479.88 billed annually.");
      setShowPayPal(true);
    } else {
      setAmount("");
      setDescription("Select a payment plan to see the amount.");
      setShowPayPal(false);
    }
  }, [paymentType]);

  return (
    <div className="container">
      <h1>Continue to your website</h1>
      <form id="signup-form" action="/process_signup.php" method="POST">
        <div className="input-group">
          <label htmlFor="name">
            Name: <span className="required">*</span>
          </label>
          <input type="text" id="name" name="name" required />
        </div>
        <div className="input-group">
          <label htmlFor="surname">
            Surname: <span className="required">*</span>
          </label>
          <input type="text" id="surname" name="surname" required />
        </div>
        <div className="input-group">
          <label htmlFor="email">
            Email: <span className="required">*</span>
          </label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className="input-group">
          <label htmlFor="phone">
            Phone Number: <span className="required">*</span>
          </label>
          <input type="text" id="phone" name="phone" required />
        </div>
        <div className="input-group">
          <label>Payment Type:</label>
          <div className="payment-toggle">
            <input
              type="radio"
              id="monthly"
              name="payment-type"
              value="monthly"
              onChange={() => setPaymentType("monthly")}
            />
            <label htmlFor="monthly" className="toggle-btn">
              Monthly/£49.99
            </label>
            <input
              type="radio"
              id="yearly"
              name="payment-type"
              value="yearly"
              onChange={() => setPaymentType("yearly")}
            />
            <label htmlFor="yearly" className="toggle-btn">
              Annual/£39.99
            </label>
          </div>
        </div>
        <div id="payment-description" className="input-group">
          <p>{description}</p>
        </div>
        <div className="input-group">
          <label htmlFor="amount">Payment Amount:</label>
          <input type="text" id="amount" name="amount" value={amount} readOnly />
        </div>
        {showPayPal && (
          <div id="paypal-button-container" className="fade-in">
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: paymentType === "yearly" ? "479.88" : "49.99",
                      },
                    },
                  ],
                });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then((details) => {
                  alert("Transaction completed by " + details.payer.name.given_name);
                });
              }}
            />
          </div>
        )}
      </form>
    </div>
  );
}

export default function PayPalApp() {
  return (
    <PayPalScriptProvider
      options={{ "client-id": "YOUR_PAYPAL_CLIENT_ID" }}
    >
      <App />
    </PayPalScriptProvider>
  );
}
